<section class="portal-header--container">
  <app-sidebar-option-title-band [card]="selectedPortalFilterCard"></app-sidebar-option-title-band>
</section>
<section class="portal-body--container">
  <div class="mb-25 container-fluid portal-cards__container">
    <div class="row">
      @for(portalCard of portalCards; track portalCard.id ){
      <div class="col-3">
        <app-filter-card (click)="clickCard()" [portalCard]="portalCard"></app-filter-card>
      </div>
      }
    </div>
  </div>
  <div class="mb-25 container-fluid">
    <div class="row">
      @if(selectedPortalFilterCard?.title !==
      PORTAL_FILTER_CARD_TITLES.REDE_MULHER_SPECIALIZED_CENTER) {
      <div class="" [ngClass]="{
        'col-12': selectedPortalFilterCard?.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR,
        'col-xxl-9 col-xl-8 col-md-8': selectedPortalFilterCard?.title !== PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR
      }">
        <app-line-chart></app-line-chart>
      </div>
      }
      @if(selectedPortalFilterCard?.title ===
      PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
      <div class="col-xxl-3 col-xl-4 col-md-4">
        <app-top-five [topFive]="topFive" [data]="topFiveData"></app-top-five>
      </div>
      } @else if(this.selectedPortalFilterCard?.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
      <div class="col-xxl-3 col-xl-4 col-md-4">
        <app-single-doughnut-card></app-single-doughnut-card>
      </div>
      }
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <app-header-box-table [headerBoxTableSettings]="tableConfig" (searchClick)="searchAndFilterItens($event)"
          (clearSearchClick)="clearSearchTable()" (swapPage)="swapPage($event)"
          (excelButtonClick)="clickExcelButton()"></app-header-box-table>
      </div>
    </div>
  </div>
</section>
