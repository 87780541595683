import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, of, throwError, timeout } from 'rxjs';
import { authSecretKey } from '../../utils';
import { environment } from '../../../../environments/environment';
export const rj190Interceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.includes(environment.baseUrl190RJ)) {
    let token;
    of(localStorage.getItem(authSecretKey)).subscribe((res) => {
      token = res;
    });
    if (token) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });

      return next(authReq).pipe(
        timeout(7000),
        catchError(err => {
          if (err.name === 'TimeoutError') {
            console.error('A requisição excedeu o tempo limite de 7 segundos.');
          }
          return throwError(() => err);
        })
      );
    } else return next(req);
  } else return next(req);
};
