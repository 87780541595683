<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<div class="add-user__container--grid">
  <div></div>
  <div class="pb-30">
    <div class="title-box">
      <div class="row border-bottom-0 pb-0">
        <div class="div-col-title col-lg-6 col-xl-6 col-12">
          <p class="title portal-body__title mb-0">Cadastrar</p>
        </div>
        <div class="div-col-back col-lg-6 col-xl-6 col-12">
          <app-dynamic-button [buttonConfig]="configButtonBack"></app-dynamic-button>
        </div>
      </div>
    </div>
    <div class="portal-body__add-annoucement-card--bigger-padding">
      <form class="mt-20" [formGroup]="formGroup">
        <div class="mb-20">
          <label class="add-user__input-label d-block mb-5" for="link">Nome*</label>
          <input class="w-100 px-10" type="text" id="name" formControlName="name" autocomplete="off" />
        </div>
        <div class="mb-20">
          <div class="row">
            <div class="col-lg-6 col-xl-6 col-12">
              <label class="add-user__input-label d-block mb-5" for="fileName">
                CPF<span [ngClass]="{'text-danger': cpf?.value && cpf?.touched && !verifyValidCpf()}">*</span>
              </label>
              <input class="w-100 px-10" type="text" id="cpf" maxlength="14" formControlName="cpf" autocomplete="off"
                mask="000.000.000-00" />
            </div>
          </div>
          <div *ngIf="cpf?.value && cpf?.touched && !verifyValidCpf()" class="add-user__input-warning mt-10">
            <span class="text-danger">CPF inválido.</span>
          </div>
        </div>
        <div class="mb-20">
          <div class="row">
            <div class="margin-bottom col-lg-6 col-xl-6 col-12">
              <label class="add-user__input-label d-block mb-5" for="link">Telefone</label>
              <input class="w-100 px-10" type="tel" id="phone" maxlength="13" formControlName="phone" autocomplete="off"
                mask="(00)0000-0000" />
            </div>
            <div class="col-lg-6 col-xl-6 col-12">
              <label class="add-user__input-label d-block mb-5" for="link">Celular*</label>
              <input class="w-100 px-10" type="tel" id="cellphone" maxlength="14" formControlName="cellphone"
                autocomplete="off" mask="(00)00000-0000" />
            </div>
          </div>
        </div>
        <div class="mb-20">
          <label class="add-user__input-label d-block mb-5" for="link">E-mail</label>
          <input class="w-100 px-10" type="email" id="email" formControlName="email" autocomplete="off" />
          <div *ngIf="email?.value && email?.touched && email?.status == 'INVALID'" class="add-user__input-warning mt-10">
            <span class="text-danger">Email inválido.</span>
          </div>
        </div>
        <div class="mb-20 position-relative">
          <label class="add-user__input-label d-block mb-5" for="link">Senha</label>
          <input class="w-100 px-10" [type]="showPass ? 'text' : 'password'" id="password" formControlName="password"
            autocomplete="off" />
          <img class="change-input--password-img" (click)="showPass = !showPass"
            [src]="showPass ? '../../../../assets/imgs/mostrar-senha.svg' : '../../../../assets/imgs/esconder-senha.svg' " />

          <div class="add-user__input-warning mt-10"
            [ngClass]="{'ok_warning': password?.value && password?.value.length >= 8 }">
            <img
              [src]="password?.value && password?.value.length >= 8 ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
            <span>A senha deve ter pelo menos 8 caracteres.</span>
          </div>

          <div class="add-user__input-warning"
            [ngClass]="{'ok_warning': password?.value && !verifyPassContainQuotation()}">
            <img
              [src]="password?.value && !verifyPassContainQuotation() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
            <span>A senha não pode conter aspas.</span>
          </div>

          <div class="add-user__input-warning"
            [ngClass]="{'ok_warning': password?.value && !verifyPassNoContainNumberAndLetters()}">
            <img
              [src]="password?.value && !verifyPassNoContainNumberAndLetters() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
            <span> A senha deve conter letras e números.</span>
          </div>

          <div class="add-user__input-warning"
            [ngClass]="{'ok_warning': password?.value && !verifyPassContainSequence()}">
            <img
              [src]="password?.value && !verifyPassContainSequence() ? '../../../../assets/imgs/circle-check_green.svg' : '../../../../assets/imgs/circle-check.svg'">
            <span>A senha não pode conter números sequenciais.; ex: 123.</span>
          </div>
        </div>
        <div class="mb-20">
          <div class="row">
            <div class="margin-bottom col-lg-6 col-xl-6 col-12">
              <label class="add-user__input-label mb-5" for="organ">Orgão/Unidade*</label>
              <select class="form-select" id="organ" formControlName="organ">
                <option selected value="0">Selecione</option>
                @for(organ of organs; track organ.ID_Orgao){
                <option [value]="organ.ID_Orgao">{{ organ.NM_Orgao }}</option>
                }
              </select>
            </div>
            <div class="col-lg-6 col-xl-6 col-12">
              <label class="add-user__input-label mb-5" for="profile">Perfil*</label>
              <select class="form-select" id="profile" formControlName="profile">
                <option selected value="0">Selecione</option>
                @for(profile of profiles; track profile.ID_Perfil){
                <option [value]="profile.ID_Perfil">{{ profile.NM_Perfil }}</option>
                }
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button (click)="saveUser()" class="mt-30 save-button" type="submit" [disabled]="!this.formGroup.valid">
            @if(this.formGroup.valid) {<img class="pr-7" src="assets/imgs/save_button_ic.svg">}
            Salvar
          </button>
        </div>
      </form>
    </div>
  </div>
  <div></div>
</div>
