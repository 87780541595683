<div class="interactions__details--card">
  <div class="d-flex align-items-center mb-20">
    <img class="user-picture" src="./../../../../assets/imgs/user-picture-2x.png" />
    <div class="username ml-30 flex-grow-1">{{ chatDetails.userName }}</div>
  </div>
  <div class="d-flex align-items-center mb-15">
    <img class="mr-10" src="./../../../../assets/imgs/blue-white-circle.svg" />
    <div class="interaction-type--label mr-5">Descrição:</div>
    <div class="interaction-type">
      {{ chatDetails.type }}
    </div>
  </div>
  <div class="d-flex align-items-center mb-15">
    <img class="mr-10" src="./../../../../assets/imgs/blue-white-circle.svg" />
    <div class="interaction-date--label mr-5">Data:</div>
    <div class="interaction-date">
      {{ chatDetails.date }}
    </div>
  </div>
  <div class="d-flex align-items-center mb-15">
    <img class="mr-10" src="./../../../../assets/imgs/blue-white-circle.svg" />
    <div class="interaction-time--label mr-5">Horário:</div>
    <div class="interaction-time">
      {{ chatDetails.time }}
    </div>
  </div>
</div>
