import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { SIDEBAR_OPTION_NAMES } from '../../../shared/enums/sidebar-option-names.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { EXTRAS_PAGES_URLS } from '../../enums/extras-pages-urls.enum';
import { EXTRAS_PAGES_NAME } from '../../enums/extra-pages-names.enum';
import { getPortalTypeFromLink } from '../../utils';
import { Subscription } from 'rxjs';
import { PortalFilterCard } from '../../models/portal-filter-card';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { PORTAL_FILTER_CARD_TITLES } from '../filter-card/enums/filter-card-titles.enum';

@Component({
  selector: 'app-sidebar-option-title-band',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sidebar-option-title-band.component.html',
  styleUrl: './sidebar-option-title-band.component.scss',
})
export class SidebarOptionTitleBandComponent implements OnInit {
  listLinks: any = [];
  myEventSubscribeCurrentPortal: Subscription | null = null;
  initComponent: boolean = false;
  @Input() card: PortalFilterCard | null;
  constructor(
    private readonly router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getLinks();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.initComponent) this.getLinks();
    if (changes['card'].currentValue) {
      let pageIsViewReported = await this.verifyPageIsViewReported();
      if (!pageIsViewReported) {
        this.pushLinkCardInArray(changes['card'].currentValue);
      } else {
        this.pushLinksForReportedView(changes['card'].currentValue);
      }
    };
  };

  async pushLinkCardInArray(card: PortalFilterCard) {
    if (this.listLinks.length > 1) this.listLinks.pop();
    if (this.listLinks[0]?.name == SIDEBAR_OPTION_NAMES.GENERAL_PANEL || SIDEBAR_OPTION_NAMES.REPORTED) {
      this.listLinks.push({
        name: card.title,
        link: this.listLinks[0]?.name == SIDEBAR_OPTION_NAMES.GENERAL_PANEL ? SIDEBAR_OPTIONS_URLS.GENERAL_PANEL : SIDEBAR_OPTIONS_URLS.REPORTED
      })
    };
  }

  verifyPageIsViewReported(): Promise<any> {
    return new Promise((resolve) => {
      let links = this.getPageByLink();
      if (links.length > 0 && links[1]?.includes(EXTRAS_PAGES_URLS.VIEW_REPORTED)) return resolve(true);
      return resolve(false);
    })
  }

  pushLinksForReportedView(card: PortalFilterCard) {
    let links = this.router.url.split('/');
    this.listLinks = [];
    this.listLinks.push({
      name: card.title,
      link: links[1] == PORTAL_TYPE_URLS.RJ_190 ? SIDEBAR_OPTIONS_URLS.REPORTED : SIDEBAR_OPTIONS_URLS.GENERAL_PANEL
    });
    let secondLink = {
      name: card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS ||
        card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS ? 'Análise Usuário' :
        card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED ? 'Usuário Bloqueado' :
          PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS,
      link: this.listLinks[0]?.name == SIDEBAR_OPTION_NAMES.GENERAL_PANEL ? SIDEBAR_OPTIONS_URLS.GENERAL_PANEL : SIDEBAR_OPTIONS_URLS.REPORTED
    };
    this.listLinks.push(secondLink);
  }

  getLinks() {
    if (!this.initComponent) {
      let links = this.getPageByLink();
      links.forEach(async urlLink => {
        let link = await this.verifyLinkName(urlLink);
        if (link) this.listLinks.push(link);
      });
      this.initComponent = true;
    }
  }

  async verifyLinkName(link: string) {
    if (link?.includes(SIDEBAR_OPTIONS_URLS.GENERAL_PANEL)) return { name: SIDEBAR_OPTION_NAMES.GENERAL_PANEL, link: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.REPORTED)) return { name: SIDEBAR_OPTION_NAMES.REPORTED, link: SIDEBAR_OPTIONS_URLS.REPORTED };
    else if (link?.includes(EXTRAS_PAGES_URLS.VIEW_REPORTED)) return { name: EXTRAS_PAGES_NAME.VIEW_REPORTED, link: EXTRAS_PAGES_URLS.VIEW_REPORTED };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.EMERGENCY)) return { name: SIDEBAR_OPTION_NAMES.EMERGENCY, link: SIDEBAR_OPTIONS_URLS.EMERGENCY };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS)) return { name: SIDEBAR_OPTION_NAMES.ANNOUNCEMENTS, link: SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS };
    else if (link?.includes(EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS)) {
      let bannerIdIsTrue = await this.getBannerId();
      return { name: bannerIdIsTrue ? EXTRAS_PAGES_NAME.EDIT_ANNOUNCEMENTS : EXTRAS_PAGES_NAME.ADD_ANNOUNCEMENTS, link: EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS };
    }
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.INTERACTIONS)) return { name: SIDEBAR_OPTION_NAMES.INTERACTIONS, link: SIDEBAR_OPTIONS_URLS.INTERACTIONS };
    else if (link?.includes(SIDEBAR_OPTIONS_URLS.USERS)) return { name: SIDEBAR_OPTION_NAMES.USERS, link: SIDEBAR_OPTIONS_URLS.USERS };
    else if (link?.includes(EXTRAS_PAGES_URLS.NEW_USER)) return { name: EXTRAS_PAGES_NAME.NEW_USER, link: EXTRAS_PAGES_URLS.NEW_USER };
    else if (link?.includes(EXTRAS_PAGES_URLS.VIEW_USER)) return { name: EXTRAS_PAGES_NAME.VIEW_USER, link: EXTRAS_PAGES_URLS.VIEW_USER };
    return;
  }

  private async getBannerId(): Promise<number> {
    return new Promise(resolve => {
      this.route.queryParams.subscribe(params => {
        resolve(params['id']);
      });
    })
  };

  getPageByLink(): string[] {
    let links = this.router.url.split('/');
    links.splice(0, 1);
    links.splice(0, 1);
    return links;
  }

  redirectLink(link: any) {
    let portal = getPortalTypeFromLink(this.router.url);
    this.router.navigate([`${portal}/${link.link}`]);
  }
}
