import { Injectable } from '@angular/core';
import Chart from 'chart.js/auto';
import { LineDataset, Subtitle } from '../models/line-dataset';
import { PortalFilterCard } from '../../../models/portal-filter-card';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { LINE_CHART_COLORS } from '../consts/line-chart-colors.enum';
import { PORTAL_TYPES } from '../../../enums/portal-types.enum';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GeneralPanel190RjService } from '../../../../190rj/services/general-panel190rj/general-panel190RJ.service';
import { GeneralPanelRedeMulherService } from '../../../services/general-panelRedeMulher/general-panelRedeMulher.service';

@Injectable({
  providedIn: 'root',
})
export class LineChartService {
  chart: any;
  months: any = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  constructor(
    private generalPanelRJ190: GeneralPanel190RjService,
    private redeMulherService: GeneralPanelRedeMulherService,
    private toastr: ToastrService
  ) { }

  async getChartData(currentFilterCard: PortalFilterCard | null, year?: any, type?: any) {
    if (!currentFilterCard) {
      return [
        new LineDataset(
          [
            750, 2000, 1000, 2500, 4750, 1100, 1400, 5000, 5200, 2500, 1000,
            1000, 1000,
          ],
          LINE_CHART_COLORS.RJ_190.USERS.IOS
        ),
        new LineDataset(
          [
            750, 2000, 7500, 4800, 3000, 14000, 5000, 7000, 6500, 5000, 5500,
            5000, 5500,
          ],
          LINE_CHART_COLORS.RJ_190.USERS.ANDROID
        ),
      ];
    }

    if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.RJ_190 &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.RJ_190_USERS
    ) {
      let values: any[] = [[], []];
      let subtitles: Subtitle[] = [];
      await firstValueFrom(this.generalPanelRJ190.statiscsYearlyUsers(year)).then((response) => {
        response.forEach((element: any) => {
          values[0].push(element.ativos);
          values[1].push(element.inativos);
        });
        subtitles.push({ blockColor: LINE_CHART_COLORS.RJ_190.USERS.ANDROID, title: 'Ativos' }, { blockColor: LINE_CHART_COLORS.RJ_190.USERS.IOS, title: 'Inativos' })
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas anual de usuários!');
        values = [];
      });
      return [
        new LineDataset(
          values[0],
          LINE_CHART_COLORS.RJ_190.USERS.ANDROID,
          0.45,
          null,
          subtitles
        ),
        new LineDataset(
          values[1],
          LINE_CHART_COLORS.RJ_190.USERS.IOS,
          0.45,
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.RJ_190 &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES
    ) {
      let values: any[] = [];
      let types: any[] = [];
      let responseTypes: any = await this.getTypesEmergencyRj190();
      if (responseTypes?.data?.length > 0) {
        types.push({ name: 'Todos', value: 0 });
        responseTypes.data.forEach((type: any) => {
          types.push({ name: type.NM_TipoChamado, value: type.ID_TipoChamado })
        });
      }
      await firstValueFrom(this.generalPanelRJ190.statiscsYearlyEmegergency(year, type)).then((response) => {
        response.forEach((element: any) => {
          values.push(element.total);
        });
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas anual de emergências!');
        values = [];
      });
      return [
        new LineDataset(
          values,
          LINE_CHART_COLORS.RJ_190.EMERGENCY,
          0.45,
          types
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.RJ_190 &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS
    ) {
      let values: any[] = [];
      let types: any[] = [];
      let responseTypes: any = await this.getTypesDennoucementsRj190();
      if (responseTypes?.data?.length > 0) {
        types.push({ name: 'Todos', value: 0 });
        responseTypes.data.forEach((type: any) => {
          types.push({ name: type.NM_TipoDenuncia, value: type.ID_TipoDenuncia })
        });
      }
      await firstValueFrom(this.generalPanelRJ190.statiscsYearlyDenouncement(year, type)).then((response) => {
        response.forEach((element: any) => {
          values.push(element.total);
        });
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas anual de denúncias!');
        values = [];
      });
      return [
        new LineDataset(
          values,
          LINE_CHART_COLORS.RJ_190.DENOUNCEMENTS,
          0.45,
          types
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.RJ_190 &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING
    ) {
      let values: any[] = [[], []];
      let types: any[] = [
        { name: 'Todos', value: 'Todos' },
        { name: 'Pessoas Encontradas', value: 'Pessoas Encontradas' },
        { name: 'Pessoas Desaparecidas', value: 'Pessoas Desaparecidas' },
      ];
      let subtitles: Subtitle[] = [];
      await firstValueFrom(this.generalPanelRJ190.statiscsYearlyMissing(year)).then((response) => {
        response.forEach((element: any) => {
          if (!type || type == 'Todos') {
            if (element.tipo == 'Pessoas Encontradas') {
              values[0].push(element.total);
            } else {
              values[1].push(element.total);
            };
          } else {
            if (element.tipo == type) {
              values[0].push(element.total);
            }
          };
        });
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas anual de desaparecidos!');
        values = [];
      });
      if (values[1].length > 0) {
        subtitles.push({ blockColor: LINE_CHART_COLORS.RJ_190.MISSING.FOUNG, title: 'Encontrados' }, { blockColor: LINE_CHART_COLORS.RJ_190.MISSING.MISSING, title: 'Desaparecidos' })
        return [
          new LineDataset(
            values[0],
            LINE_CHART_COLORS.RJ_190.MISSING.FOUNG,
            0.45,
            types,
            subtitles
          ),
          new LineDataset(
            values[1],
            LINE_CHART_COLORS.RJ_190.MISSING.MISSING,
            0.45,
          ),
        ];
      } else {
        return [
          new LineDataset(
            values[0],
            type == 'Pessoas Encontradas' ? LINE_CHART_COLORS.RJ_190.MISSING.FOUNG : LINE_CHART_COLORS.RJ_190.MISSING.MISSING,
            0.45,
            types
          ),
        ];
      }
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.REDE_MULHER &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS
    ) {
      let values: any[] = [[], []];
      let subtitles: Subtitle[] = [];
      await firstValueFrom(this.redeMulherService.statiscsYearlyUsers(year)).then((response) => {
        response.forEach((element: any) => {
          values[0].push(element.ativos);
          values[1].push(element.inativos);
        });
        subtitles.push({ blockColor: LINE_CHART_COLORS.REDE_MULHER.USERS.ANDROID, title: 'Ativos' }, { blockColor: LINE_CHART_COLORS.REDE_MULHER.USERS.IOS, title: 'Inativos' })
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas anual de usuários!');
        values = [];
      });
      return [
        new LineDataset(
          values[0],
          LINE_CHART_COLORS.REDE_MULHER.USERS.ANDROID,
          0.45,
          null,
          subtitles
        ),
        new LineDataset(
          values[1],
          LINE_CHART_COLORS.REDE_MULHER.USERS.IOS,
          0.45,
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.REDE_MULHER &&
      currentFilterCard.title ===
      PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES
    ) {
      let values: any[] = [];
      let types: any[] = [];
      let responseTypes: any = await this.getTypesEmergencyRedeMulher();
      if (responseTypes?.data?.length > 0) {
        types.push({ name: 'Todos', value: 0 });
        responseTypes.data.forEach((type: any) => {
          types.push({ name: type.NM_TipoChamado, value: type.ID_TipoChamado })
        });
      }
      await firstValueFrom(this.redeMulherService.statiscsYearlyEmergency(year, type)).then((response) => {
        response.forEach((element: any) => values.push(element.total));
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas anual de emergencias!');
        values = [];
      });
      return [
        new LineDataset(
          values,
          LINE_CHART_COLORS.REDE_MULHER.EMERGENCY,
          0.45,
          types
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.REDE_MULHER &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR
    ) {
      let values: any[] = [[], []];
      let subtitles: Subtitle[] = [];
      await firstValueFrom(this.redeMulherService.statiscsYearlyAggressor(year)).then((response) => {
        response.forEach((element: any) => {
          values[0].push(element.ativos);
          values[1].push(element.inativos);
        });
        subtitles.push({ blockColor: LINE_CHART_COLORS.RJ_190.USERS.ANDROID, title: 'Ativos' }, { blockColor: LINE_CHART_COLORS.RJ_190.USERS.IOS, title: 'Inativos' })
      }).catch(() => {
        this.openToastError('Erro ao buscar estatísticas anual de usuários!');
        values = [];
      });
      return [
        new LineDataset(
          values[0],
          LINE_CHART_COLORS.REDE_MULHER.USERS.ANDROID,
          0.45,
          null,
          subtitles
        ),
        new LineDataset(
          values[1],
          LINE_CHART_COLORS.RJ_190.USERS.IOS,
          0.45,
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard.title === PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_USERS
    ) {
      return [
        new LineDataset(
          [
            750, 2000, 1000, 2500, 4750, 1100, 1400, 5000, 5200, 2500, 1000,
            1000,
          ],
          LINE_CHART_COLORS.REDE_ESCOLA.USERS.IOS
        ),
        new LineDataset(
          [
            750, 2000, 7500, 4800, 5000, 14000, 5000, 7000, 6500, 5000, 5500,
            5000,
          ],
          LINE_CHART_COLORS.REDE_ESCOLA.USERS.ANDROID
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard.title ===
      PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS
    ) {
      return [
        new LineDataset(
          [
            750, 2000, 1000, 2500, 4750, 1100, 1400, 5000, 5200, 2500, 1000,
            1000,
          ],
          LINE_CHART_COLORS.REDE_ESCOLA.DENOUNCEMENTS
        ),
      ];
    } else if (
      currentFilterCard.selectedPortal.type === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard.title ===
      PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES
    ) {
      return [
        new LineDataset(
          [
            750, 2000, 1000, 2500, 4750, 1100, 1400, 5000, 5200, 2500, 1000,
            1000,
          ],
          LINE_CHART_COLORS.REDE_ESCOLA.EMERGENCY
        ),
      ];
    }
    return [
      new LineDataset(
        [],
        LINE_CHART_COLORS.RJ_190.USERS.IOS
      ),
      new LineDataset(
        [],
        LINE_CHART_COLORS.RJ_190.USERS.ANDROID
      ),
    ];
  }

  buildChart(labels: string[], datasets: LineDataset[]): any {
    this.chart?.destroy();
    this.chart = new Chart('myChart', {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove'],
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            backgroundColor: '#85A3BBCC',
            displayColors: true,
            cornerRadius: 4,
            callbacks: {
              label: function (context) {
                let label = '';
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('pt-BR').format(
                    context.parsed.y
                  );
                }
                return label;
              },
              labelColor: function (context) {
                return {
                  borderColor: '#85A3BBCC',
                  backgroundColor: '',
                  borderWidth: 0,
                  borderRadius: 4,
                };
              },
              labelTextColor: function (context) {
                return '#FFFFFF';
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#B3B8BD',
              callback: function (value, index, ticks) {
                let formatedValue = value.toString();
                if (+value >= 1000) {
                  formatedValue = +value / 1000 + 'k';
                }
                return formatedValue;
              },
            },
          },
          x: {
            grid: {
              display: false,
            },

            beginAtZero: true,
            ticks: {
              color: '#B3B8BD',
            },
          },
        },
      },
    });
  };

  private async getTypesEmergencyRj190() {
    let response;
    await firstValueFrom(this.generalPanelRJ190.getTypesEmergency()).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

  private async getTypesDennoucementsRj190() {
    let response;
    await firstValueFrom(this.generalPanelRJ190.getTypesDennoucements()).then((resService) => response = resService).catch(() => response = []);
    return response;
  }

  private async getTypesEmergencyRedeMulher() {
    let response;
    await firstValueFrom(this.redeMulherService.emergencyTypes()).then((resService) => response = resService).catch(() => response = []);
    return response;
  }


  openToastError(text: string): void {
    this.toastr.error(text, 'Erro!');
  }
}
