import { CommonModule, PercentPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { DailyUserAnalysisChartService } from './services/daily-user-analysis-chart.service';
import { UserAnalysisDataset } from './models/user-analysis-dataset';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { PortalTypeService } from '../../services/portal-type.service';


@Component({
  selector: 'app-daily-user-analysis',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PercentPipe],
  templateUrl: './daily-user-analysis.component.html',
  styleUrl: './daily-user-analysis.component.scss',
})
export class DailyUserAnalysisComponent implements OnInit {
  currentPortalType: PORTAL_TYPES =
    this._portalTypeService.currentPortalType$.value.type;

  title: string = 'Análise Diária Usuários';
  following = {
    startedFollowing: 0.75,
    totalFollowing: 1,
  };

  followers = {
    startedFollowing: 100,
    totalStartedFollowing: 133.33,
    unfollowed: 20,
    totalUnfollowed: 26.3157,
  };

  chart1: any;
  chart2: any;

  periods = [{ id: 1, name: 'Dia' }, { id: 2, name: 'Última semana' }, { id: 3, name: 'Últimos 30 dias' }];

  formBuilder: FormBuilder = new FormBuilder();
  formGroup: FormGroup = new FormGroup([]);

  constructor(
    private readonly _dailyUserAnalysisChartService: DailyUserAnalysisChartService,
    private _portalTypeService: PortalTypeService
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.getChartData();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      period: ['Dia'],
    });
  }

  listensToPortalChanges() {
    this._portalTypeService.currentPortalType$.subscribe((currentPortal) => {
      this.currentPortalType = currentPortal.type;
    });
  }

  getChartData() {
    this._dailyUserAnalysisChartService
      .getChartData('', this.currentPortalType)
      .subscribe((chartData: any) => {
        if (chartData) {
          this.chart1 = this.buildChart(
            ['a', 'b'],
            chartData.following.dataset,
            this.chart1,
            '1'
          );
          this.chart2 = this.buildChart(
            ['a', 'b'],
            chartData.unfollowing.dataset,
            this.chart2,
            '2'
          );
        }
      });
  }

  buildChart(
    labels: string[],
    dataset: UserAnalysisDataset,
    chart: any,
    charName: string
  ) {
    if (chart) {
      chart.destroy();
    }

    return this._dailyUserAnalysisChartService.buildChart(
      labels,
      dataset,
      charName
    );
  }
}
