import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getUsers(limit?: number, page?: number, name?: string, profileID?: string): Observable<any> {
    if (!limit) limit = 10;
    if (!page) page = 1;
    if (profileID == "todos") profileID = "";
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `usuario?${profileID ? `perfilId=${profileID}&` : ''}${name ? `nome=${name}&` : ''}page=${page}&limit=${limit}`,
    };
    return this.restApiService.get(request);
  };

  createUser(body: any) {
    let endpoint = this.baseUrl + `usuario`;
    return this.restApiService.post(endpoint, body);
  };

  getProfiles(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'usuario/listar-perfil',
    };
    return this.restApiService.get(request);
  };

  getOrgans(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'usuario/listar-orgao',
    };
    return this.restApiService.get(request);
  };
}
