import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InteractionDetailsService {
  constructor() {}

  getInteractionDetails() {
    return of({
      userName: 'Lorem Ipsum',
      type: 'Maus tratos a vulneráveis',
      date: '12/12/2023',
      time: '09:00 am',
    });
  }
}
