import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { FilterCardsService } from '../filter-card/services/filter-cards.service';
import { PortalFilterCard } from '../../models/portal-filter-card';
import { SingleDoughtnutCardService } from './services/single-doughtnut-card.service';
import {
  DoughtnutCardLabel,
} from './models/chart-data.interface';
import { PORTAL_FILTER_CARD_TITLES } from '../filter-card/enums/filter-card-titles.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-single-doughnut-card',
  standalone: true,
  imports: [CommonModule, DecimalPipe],
  templateUrl: './single-doughnut-card.component.html',
  styleUrl: './single-doughnut-card.component.scss',
})
export class SingleDoughnutChartComponent implements OnInit, OnDestroy {
  chartTitle: string = '';
  chartTitle2: string | null = null;
  cardLabels: Array<DoughtnutCardLabel> = [];
  myEventSubscribePortalService: Subscription | null = null;
  chart: any;
  currentYear = new Date().getFullYear();

  constructor(
    private readonly _filterCardsService: FilterCardsService,
    private _singleDoughtnutCardService: SingleDoughtnutCardService
  ) { }

  ngOnInit() {
    this.listenToFilterCardsChanges();
  };

  ngOnDestroy() {
    if (this.myEventSubscribePortalService) this.myEventSubscribePortalService.unsubscribe();
  }

  listenToFilterCardsChanges() {
    this.myEventSubscribePortalService = this._filterCardsService.currentPortalFilterCard$.subscribe((selectedPortalFilterCard: any) => {
      if (this.verifyAvaliableDoughNut(selectedPortalFilterCard)) {
        this.getChartData(selectedPortalFilterCard.selectedPortal.type, selectedPortalFilterCard);
      }
    });
  }

  async getChartData(portalType: PORTAL_TYPES, currentFilterCard: any) {
    let chartData: any = await this._singleDoughtnutCardService.getChartData(portalType, currentFilterCard);
    if (chartData && document.getElementById('doughnut-chart')) {
      this.cardLabels = chartData.cardLabels;
      this.chartTitle = chartData.cardTitle;
      this.chartTitle2 = chartData.cardTitle2 ? chartData.cardTitle2 : '';
      if (chartData.dataset) this._singleDoughtnutCardService.buildChart(chartData.dataset);
    };
  }

  getTotalLabelValues() {
    return this.cardLabels.reduce(
      (accumulator, cardLabel) => accumulator + cardLabel.value,
      0
    );
  };

  verifyAvaliableDoughNut(portal: PortalFilterCard | null): boolean {
    if (portal?.selectedPortal.type == PORTAL_TYPES.RJ_190) {
      if (
        portal.title == PORTAL_FILTER_CARD_TITLES.RJ_190_USERS ||
        portal.title == PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING
      ) return true;
    } else if (portal?.selectedPortal.type == PORTAL_TYPES.REDE_MULHER) {
      if (portal.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) return true;
    }
    return false;
  }
}
