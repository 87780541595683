<button class="dynamic_button" [ngClass]="{
  '_outlined': buttonConfig.outlined,
  '_disabled': buttonConfig.disabled,
  'rede_mulher_color': urlActive.includes(portalTypes.REDE_MULHER),
  'rj190_color':  urlActive.includes(portalTypes.RJ_190),
  'rede_escola_color': urlActive.includes(portalTypes.REDE_ESCOLA)
  }" [ngStyle]="{
  'background-color': buttonConfig.customColor?.backgroundColor,
  'padding-inline': buttonConfig.customPadding?.padding_inLine,
  'padding-bottom': buttonConfig.customPadding?.padding_bottom,
  'padding-top': buttonConfig.customPadding?.padding_top,
  'width': buttonConfig.customWidth
  }"
  (click)="buttonConfig.handler ? buttonConfig.handler() : false">
  @if (buttonConfig.imgSrc) {<img [src]="buttonConfig.imgSrc">}
  <span class="label" [ngStyle]="{'color': buttonConfig.customColor?.colorText}">
    {{buttonConfig.label}}
  </span></button>
