import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { EXTRAS_PAGES_URLS } from '../../../shared/enums/extras-pages-urls.enum';
import { UsersService } from '../../services/users-page/users.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { TableUsers190RJService } from '../../services/dynamic-table/dynamic-table__users-page-190rj.service';


@Component({
  selector: 'app-users-page190rj',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './users-page190rj.component.html',
  styleUrl: './users-page190rj.component.scss'
})
export class UsersPage190rjComponent implements OnInit {
  configButtonRegister: ButtonConfig = { label: 'Usuário', imgSrc: "assets/imgs/icon_more.svg", handler: () => this.registerUser(), customColor: { backgroundColor: "#60B863" } };
  tableConfig: any = {};
  show: boolean = false;
  profiles: any[] = [];
  organs: any[] = [];
  querySearch: string = "";
  filterQuery: string = "";

  constructor(
    private tableService: TableUsers190RJService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private usersService: UsersService
  ) { }


  async ngOnInit() {
    this.loadingService.present();
    this.tableConfig = await this.getData();
    this.profiles = this.tableConfig.optionsFirstFilter;
    this.organs = await this.getOrgans();
    this.getOrgansById(this.tableConfig.tableBody);
    this.getProfilesById(this.tableConfig.tableBody);
    this.tableConfig.currentPage = 1;
    this.show = true;
    this.loadingService.dismiss();
  }


  public async searchAndFilterItens(event: any) {
    this.querySearch = event.searchValue;
    this.filterQuery = event.filterFirstValue;
    if (this.querySearch || this.filterQuery) {
      this.loadingService.present();
      this.tableConfig = await this.getData(1, this.querySearch, this.filterQuery, true);
      this.getOrgansById(this.tableConfig.tableBody);
      this.getProfilesById(this.tableConfig.tableBody);
      this.tableConfig.currentPage = 1;
      this.tableConfig.optionsFirstFilter = this.profiles;
      this.loadingService.dismiss();
    }
  }

  public async clearSearchTable() {
    this.loadingService.present();
    this.querySearch = "";
    this.filterQuery = "";
    this.tableConfig = await this.getData(1, '', '', true);
    this.getOrgansById(this.tableConfig.tableBody);
    this.getProfilesById(this.tableConfig.tableBody);
    this.tableConfig.currentPage = 1;
    this.tableConfig.optionsFirstFilter = this.profiles;
    this.loadingService.dismiss();
  }
  public registerUser() {
    this.router.navigate([EXTRAS_PAGES_URLS.NEW_USER], { relativeTo: this.route });
  }

  private async getData(page?: number, name?: string, profileID?: string, isItSearch?: boolean): Promise<any> {
    return this.tableService.getSettingsTable(page, name, profileID, isItSearch);
  };

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = await this.getData(page, this.querySearch, this.filterQuery, true);
    this.getOrgansById(this.tableConfig.tableBody);
    this.getProfilesById(this.tableConfig.tableBody);
    this.tableConfig.currentPage = page;
    this.tableConfig.optionsFirstFilter = this.profiles;
    this.loadingService.dismiss();
  }

  async getOrgans(): Promise<any> {
    return new Promise(resolve => {
      this.usersService.getOrgans().subscribe({
        next: (response) => resolve(response.data),
        error: () => resolve([])
      })
    })
  }

  getOrgansById(array: any) {
    array.forEach((user: any) => {
      let organ: any = this.organs.find(org => org.ID_Orgao == user.organID);
      if (organ) {
        user.organ = organ.NM_Orgao;
        delete user.organID;
      }
    });
  }

  getProfilesById(array: any) {
    array.forEach((user: any) => {
      let profile: any = this.profiles.find(prf => prf.value == user.profileID);
      if (profile) {
        user.profile = profile.name;
        delete user.profileID;
      }
    });
  }
}
