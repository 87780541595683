import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
import { IRestApiCall } from '../../../shared/services/rest-api/rest-api-call';
@Injectable({
  providedIn: 'root'
})
export class GeneralPanel190RjService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getTotalStatics(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'painel/total',
    };
    return this.restApiService.get(request);
  };

  statiscsMonthlyUsers() {
    let year = new Date().getFullYear();
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/estatistica-mensal?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  activeInativeUsers() {
    let year = new Date().getFullYear();
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/total-ativos-inativos',
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyUsers(year: number) {
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  emergencyTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'emergencias/top-rank',
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyEmegergency(year: number, type: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/estatistica-anual?ano=${year}${type > 0 ? '&tipoEmergencia=' + type : ''}`,
    };
    return this.restApiService.get(request);
  };

  getTypesEmergency(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'emergencias/listar-tipos-emergencias',
    };
    return this.restApiService.get(request);
  };

  generalPanelEmergencyTable(limit?: number, page?: number, year?: string) {
    if (!limit) limit = 5;
    if (!page) page = 1;
    if (!year) year = new Date().getFullYear().toString();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/detalhes-anual?limit=${limit}&page=${page}&ano=${year}`,
    };
    return this.restApiService.get(request);
  };

  generalPanelEmergencyTableExcelExport(year?: string) {
    if (!year) year = new Date().getFullYear().toString();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `emergencias/detalhes-anual/excel?ano=` + year,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyDenouncement(year: number, type: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `denuncias/estatistica-anual?ano=${year}${type > 0 ? '&tipoDenuncia=' + type : ''}`,
    };
    return this.restApiService.get(request);
  };

  getTypesDennoucements(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'denuncias/listar-tipos-denuncias',
    };
    return this.restApiService.get(request);
  }

  denouncementTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'denuncias/top-rank',
    };
    return this.restApiService.get(request);
  };

  denouncementTable(limit?: number, page?: number, year?: string) {
    if (!limit) limit = 5;
    if (!page) page = 1;
    if (!year) year = new Date().getFullYear().toString();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `denuncias/detalhes-anual?limit=${limit}&page=${page}&ano=${year}`,
    };
    return this.restApiService.get(request);
  };

  generalPanelDenoucementTableExcelExport(year?: string) {
    if (!year) year = new Date().getFullYear().toString();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `denuncias/detalhes-anual/excel?ano=` + year,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyMissing(year: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'desaparecidos/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  foungMissingTotal() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'desaparecidos/total-desaparecidos-encontrados',
    };
    return this.restApiService.get(request);
  };

  missingTable(year?: string) {
    if (!year) year = new Date().getFullYear().toString();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'desaparecidos/detalhes-anual?ano=' + year
    };
    return this.restApiService.get(request);
  };

  generalPanelMissingTableExcelExport(year?: any) {
    if (!year) year = new Date().getFullYear().toString();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `desaparecidos/detalhes-anual/excel?ano=` + year,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  };
}
