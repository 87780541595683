import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { rj190Interceptor } from './shared/interceptors/190rj/rj190.interceptor';
import { provideToastr } from 'ngx-toastr';


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideToastr({
    timeOut: 2000,
    positionClass: 'toast-top-center',
    preventDuplicates: true,
    closeButton: true
  }), provideAnimationsAsync(), provideHttpClient(withInterceptors([
    rj190Interceptor
  ])),]
};
