export enum PORTAL_FILTER_CARD_IMAGES {
  RJ_190_USERS = 'user',
  RJ_190_EMERGENCIES = 'emergency-on',
  RJ_190_DENOUNCEMENTS = 'megaphone',
  RJ_190_MISSING = 'missing',
  RJ_190_REPORTS = 'reports',
  RJ_190_OPEN_STATUS = 'status-open',
  RJ_190_BLOCKED = 'blocked',
  RJ_190_CLOSED_STATUS = 'status-closed',
  REDE_MULHER_USERS = 'user',
  REDE_MULHER_EMERGENCIES = 'emergency-on',
  REDE_MULHER_SPECIALIZED_CENTER = 'megaphone',
  REDE_MULHER_AGRESSOR = 'agressor',
  REDE_ESCOLA_USERS = 'user',
  REDE_ESCOLA_SCHOOLS = 'schools',
  REDE_ESCOLA_DENOUNCEMENTS = 'megaphone',
  REDE_ESCOLA_EMERGENCIES = 'emergency-on',
}
