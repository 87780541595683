import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PORTAL_FILTER_CARD_TITLES } from '../../../shared/components/filter-card/enums/filter-card-titles.enum';
import { FilterCardComponent } from '../../../shared/components/filter-card/filter-card.component';
import { FilterCardsService } from '../../../shared/components/filter-card/services/filter-cards.service';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { EXTRAS_PAGES_URLS } from '../../../shared/enums/extras-pages-urls.enum';
import { PORTAL_TYPES } from '../../../shared/enums/portal-types.enum';
import { PortalFilterCard } from '../../../shared/models/portal-filter-card';
import { ReportsService } from '../../services/reports-page/reports.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { TableReported190RJService } from '../../services/dynamic-table/dynamic-table__reported-page-190rj.service';

@Component({
  selector: 'app-reported-page190rj',
  standalone: true,
  imports: [
    HeaderBoxTableComponent,
    SidebarOptionTitleBandComponent,
    FilterCardComponent,
  ],
  templateUrl: './reported-page190rj.component.html',
  styleUrl: './reported-page190rj.component.scss',
})
export class ReportedPage190rjComponent implements OnInit, OnDestroy {
  portalCards: Array<PortalFilterCard> = [];
  portalCardsSubscription: Subscription | null = null;
  selectedPortalFilterCard: PortalFilterCard | null = null;
  PORTAL_FILTER_CARD_TITLES = PORTAL_FILTER_CARD_TITLES;
  tableConfig: any = {};
  show: boolean = false;
  myEventSubscribeCurrentPortal: Subscription | null = null;
  searchQuery: string = "";

  constructor(
    private _portalFilterCardsService: FilterCardsService,
    private router: Router,
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private toastr: ToastrService,
    private _tableService: TableReported190RJService,
    private loadingService: LoadingService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.emitNewPortalFilterCards();
    this.listenToNewPortalCards();
    this.listenToChangesOnSelectedPortalFilterCard();
    await this.getNumericDatas();
  };

  ngOnDestroy() {
    if (this.portalCardsSubscription) this.portalCardsSubscription?.unsubscribe();
    if (this.myEventSubscribeCurrentPortal) this.myEventSubscribeCurrentPortal?.unsubscribe();
  };

  getNumericDatas(): Promise<boolean> {
    return new Promise((resolve) => {
      this.reportsService.getTotalReports().subscribe({
        next: (response: any) => {
          this.portalCards.forEach(card => {
            if (card.title == 'Reports') card.value = response.totalReports;
            if (card.title == 'Status Aberto') card.value = response.totalStatusAberto;
            if (card.title == 'Status Fechado') card.value = response.totalStatusFechado;
            // if (card.title == 'Bloqueados') card.value = response.totalBloqueados;
          });
        },
        error: () => {
          this.toastr.error('Erro ao pegar dados númericos!', 'Erro!');
        }
      }).add(() => resolve(true));
    })
  };

  emitNewPortalFilterCards() {
    let newFiltersCards = [
      { id: 1, title: 'Reports', value: 0 },
      { id: 2, title: 'Status Aberto', value: 0 },
      { id: 3, title: 'Bloqueados', value: 0 },
      { id: 4, title: 'Status Fechado', value: 0 },
    ];
    this._portalFilterCardsService.emitNewPortalFilterCards(
      PORTAL_TYPES.RJ_190,
      newFiltersCards
    );
  }

  listenToNewPortalCards() {
    this.portalCardsSubscription =
      this._portalFilterCardsService.portalFilterCard$.subscribe(
        (portalCards: Array<PortalFilterCard>) => {
          this.portalCards = portalCards;
        }
      );
  }

  listenToChangesOnSelectedPortalFilterCard() {
    this.myEventSubscribeCurrentPortal = this._portalFilterCardsService.currentPortalFilterCard$.subscribe(
      (selectedPortalFilterCard: PortalFilterCard | null) => {
        if (selectedPortalFilterCard) {
          this.selectedPortalFilterCard = selectedPortalFilterCard;
          this.getTableData();
          this.portalCards.forEach((portalCard: PortalFilterCard) => {
            portalCard.active = false;
            if (portalCard.id === selectedPortalFilterCard.id) {
              portalCard.active = true;
            }
          });
        }
      }
    );
  }

  private async getTableData() {
    this.show = false;
    this.loadingService.present();
    this.searchQuery = "";
    this.tableConfig = await this.getData();
    this.tableConfig.currentPage = 1;
    this.configHandleButton();
    this.loadingService.dismiss();
    this.show = true;
  }

  public async searchAndFilterItens(event: any) {
    this.searchQuery = event.searchValue;
    if (this.searchQuery) {
      this.loadingService.present();
      this.tableConfig = await this.getData(1, event.searchValue);
      this.tableConfig.currentPage = 1;
      this.configHandleButton();
      this.loadingService.dismiss();
    }
  };

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = await this.getData(page, this.searchQuery);
    this.tableConfig.currentPage = page;
    this.configHandleButton();
    this.loadingService.dismiss();
  };


  public async clearSearchTable() {
    this.loadingService.present();
    this.tableConfig = await this.getData(1);
    this.tableConfig.currentPage = 1;
    this.searchQuery = "";
    this.configHandleButton();
    this.loadingService.dismiss();
  };

  configHandleButton() {
    this.tableConfig.buttonTable.handler = (dataObjectTable: any) => {
      this.openVisualize(dataObjectTable);
    };
  }

  openVisualize(data: any) {
    this.router.navigate([EXTRAS_PAGES_URLS.VIEW_REPORTED], { relativeTo: this.route, state: { reportData: data, card: this.selectedPortalFilterCard } });
  }

  private getData(page?: any, query?: string): Promise<any> {
    return this._tableService.getSettingsTable(this.selectedPortalFilterCard, page, query);
  };
}
