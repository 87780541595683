import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HeaderTableSettings } from "../../../shared/components/header-box-table/header-box-table.model";
import { formatResponseTableEmergency } from "../../../shared/utils";
import { EmergencyService } from "../emergency-page/emergency.service";

@Injectable({
  providedIn: 'root'
})

export class TableEmergency190RJService {
  constructor(
    private emergencyService: EmergencyService,
  ) { }

  public async getSettingsTable(pageNumber?: number, type?: string, status?: string, initDate?: string, finalDate?: string, isItSearch?: boolean) {
    let config = {} as HeaderTableSettings;
    config.optionsFirstFilter = [{ name: "Todos", value: "Todos" }];
    config.optionsSecondFilter = [{ name: "Todos", value: "Todos" }];
    let responseEmergency = await this.getDataTableByCard(pageNumber, type, status, initDate, finalDate);
    if (responseEmergency?.data?.length > 0) {
      responseEmergency.data = formatResponseTableEmergency(responseEmergency.data);
    }
    if (!isItSearch) {
      let typesFilter: any = await this.getTypesEmergencyRj190();
      let statusFilter: any = await this.getStatusEmergencyRj190();
      if (typesFilter?.data?.length > 0) {
        typesFilter.data.forEach((type: any) => {
          config.optionsFirstFilter?.push({ name: type.NM_TipoChamado, value: type.ID_TipoChamado });
        });
      }
      if (statusFilter?.data?.length > 0) {
        statusFilter.data.forEach((stat: any) => {
          config.optionsSecondFilter?.push({ name: stat.NM_Status, value: stat.ID_Status });
        });
      }
    }
    config.headerTitle = "Emergência";
    config.itensPerPage = 10;
    config.totalItens = responseEmergency.total;
    config.showTextBox = false;
    config.showFirstFilterBox = true;
    config.showSecondFilterBox = true;
    config.showFilterByDate = true;
    config.showSecondFilterByDate = true;
    config.showClearButton = true;
    config.titlesTables = ['Data', 'Hora', 'Tipo', 'Mike 190', 'Status', 'Satisfação'];
    config.tableBody = responseEmergency.data;
    return config;
  }

  private async getDataTableByCard(pageNumber?: number, type?: string, status?: string, initDate?: string, finalDate?: string) {
    let response: any;
    await firstValueFrom(this.emergencyService.emergencyPageTable(10, pageNumber, type, status, initDate, finalDate)).then((responseAPI) => response = responseAPI).catch(() => response = []);
    return response;
  }

  private async getTypesEmergencyRj190() {
    let response;
    await firstValueFrom(this.emergencyService.emergencyGetTypes()).then((responseAPI) => response = responseAPI).catch(() => response = []);
    return response;
  }

  private async getStatusEmergencyRj190() {
    let response;
    await firstValueFrom(this.emergencyService.emergencyGetStatus()).then((responseAPI) => response = responseAPI).catch(() => response = []);
    return response;
  }

}
