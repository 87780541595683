import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { GeneralPanelRedeMulherService } from "../../shared/services/general-panelRedeMulher/general-panelRedeMulher.service";
import { MockDataTable } from "../../shared/components/dynamic-table/services/dynamic-table.mocks";
import { PORTAL_FILTER_CARD_TITLES } from "../../shared/components/filter-card/enums/filter-card-titles.enum";
import { HeaderTableSettings } from "../../shared/components/header-box-table/header-box-table.model";


@Injectable({
  providedIn: 'root'
})

export class TableGeneralPanelRedeMulherService {
  constructor(private generalPanelService: GeneralPanelRedeMulherService,) { }

  public async getSettingsTable(page?: number, clickedCard?: any, queryText?: string, filterID?: string, isItSearch?: boolean) {
    let config: HeaderTableSettings = {};
    let responseAPI = await this.getDataTableByCard(page, clickedCard, queryText, filterID);
    if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
      config.totalItens = responseAPI?.total;
      config.headerTitle = 'Todos os ' + clickedCard.title;
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.titleSearch = 'Pesquise por nome';
      config.showFirstFilterBox = true;
      config.showClearButton = true;
      config.showExcelButton = true;
      config.optionsFirstFilter = [
        { name: 'Todos', value: 'todos' },
        { name: 'Ativo', value: 'true' },
        { name: 'Inativo', value: 'false' },
      ];
      config.titlesTables = [
        'Nome',
        'CPF',
        'Telefone',
        'E-mail',
        'Status',
        'Ver Perfil',
      ];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = responseAPI?.data ? responseAPI?.data : [];
    } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
      config.totalItens = responseAPI?.total;
      config.headerTitle = clickedCard.title;
      config.itensPerPage = 5;
      config.showTextBox = false;
      config.showFirstFilterBox = true;
      config.showExcelButton = true;
      config.optionsFirstFilter = [{ name: 'Todos', value: 'todos' }];
      if (!isItSearch) {
        let typesFilter: any = await this.getTypesEmergency();
        if (typesFilter.data.length > 0) {
          typesFilter.data.forEach((type: any) => {
            config.optionsFirstFilter?.push({ name: type.NM_TipoChamado, value: type.ID_TipoChamado });
          });
        }
      }
      config.titlesTables = [
        'Tipo',
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
        'Total'];
      config.showButton = false;
      config.tableBody = responseAPI?.data ? responseAPI?.data : [];
    } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_SPECIALIZED_CENTER) {
      config.totalItens = responseAPI?.total;
      config.headerTitle = clickedCard.title;
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.titleSearch = 'Pesquisar por nome ou palavra';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearButton = true;
      config.titlesTables = [
        'Autor',
        'Data',
        'Horário',
        'Qt. de Reports',
        'Resumo do Post',
        'Ver Perfil',
      ];
      config.showButton = true;
      config.buttonTable = { label: 'Visualizar' };
      config.tableBody = responseAPI?.data ? responseAPI?.data : [];

    } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
      config.totalItens = responseAPI?.total;
      config.headerTitle = 'Todos os' + ' ' + clickedCard.title;
      config.itensPerPage = 10;
      config.showTextBox = true;
      config.titleSearch = 'Pesquisar por nome do agressor';
      config.showFirstFilterBox = false;
      config.showSecondFilterBox = false;
      config.showClearIc = true;
      config.titlesTables = [
        'Agressor',
        'CPF',
        'Medida Protetiva',
        'Data do Vencimento',
        'Vítima',
      ];
      config.showButton = false;
      config.tableBody = responseAPI?.data ? responseAPI?.data : [];
    };
    return config;
  }

  private async getDataTableByCard(page?: number, cardOption?: any, queryText?: string, filterID?: string) {
    let response: any;
    if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
      await firstValueFrom(this.generalPanelService.usersTable(10, page, queryText, filterID)).then((responseAPI: any) => response = responseAPI).catch(() => response = null);
    } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
      await firstValueFrom(this.generalPanelService.emergencyTable(5, page, filterID)).then((responseAPI: any) => response = responseAPI).catch(() => response = null);
      if (response) {
        let newResponse: any = [];
        response?.data?.forEach((element: any) => {
          let newObject = {
            type: element?.tipoChamado,
            jan: element?.Jan ? element.Jan : 0,
            fev: element?.Fev ? element.Fev : 0,
            mar: element?.Mar ? element.Mar : 0,
            abr: element?.Abr ? element.Abr : 0,
            mai: element?.Mai ? element.Mai : 0,
            jun: element?.Jun ? element.Jun : 0,
            jul: element?.Jul ? element.Jul : 0,
            ago: element?.Ago ? element.Ago : 0,
            set: element?.Set ? element.Set : 0,
            out: element?.Out ? element.Out : 0,
            nov: element?.Nov ? element.Nov : 0,
            dez: element?.Dez ? element.Dez : 0,
            total: element?.totalGeral
          };
          newResponse.push(newObject);
        });
        response.data = newResponse;
      }
    } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_SPECIALIZED_CENTER) {
      await firstValueFrom(this.generalPanelService.reportedTable()).then((resService: any) => {
        response = resService.Results;
      }).catch(() => {
        response = [];
      });
      response = MockDataTable.REDE_MULHER.GENERAL_PANEL.REPORTED;
    } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
      await firstValueFrom(this.generalPanelService.agressorTable()).then((resService: any) => {
        response = resService.Results;
      }).catch(() => {
        response = [];
      });
    };
    return response;
  }

  private async getTypesEmergency() {
    let response;
    await firstValueFrom(this.generalPanelService.emergencyTypes()).then((responseAPI) => response = responseAPI).catch(() => response = []);
    return response;
  }

}
