<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container relative">
  <input type="checkbox" id="check-three-points">
  <label for="check-three-points" class="checkbtn">
    <div class="point"></div>
    <div class="point"></div>
    <div class="point"></div>
  </label>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <p class="portal-body__title">Interações Usuários</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="portal-body__interactions--grid">
          <div class="interactions__users-list--card">
            <div class="w-100 px-25">
              <select class="form-select mb-12" [(ngModel)]="selectedDennoucementType">
                @for (type of dennoucementsTypes; track $index) {
                <option [value]="type.id">{{type.name}}</option>
                }
              </select>
            </div>
            <div class="actions_box">
              <form class="w-100 interactions__users_list--form position-relative" [formGroup]="formGroup">
                <img class="interactions__users_list--magnifying-glass"
                  src="./../../../../assets/imgs/magnifying-glass.svg" />
                <input class="pl-35 w-100" type="text" formControlName="userName"
                  placeholder="Pesquisar por Denúncia" />
              </form>
              <div class="button_box">
                <app-dynamic-button [buttonConfig]="configButtonSearch"></app-dynamic-button>
              </div>
            </div>

            <!-- <div class="w-100 px-25">

            </div> -->
            <div class="interactions__user-list--container mt-12 ml-25">
              @for(dennoucement of dennoucements; track dennoucement.id){
              <div class="interactions__dennoucements_type-list--grid"
                [ngClass]="{'selected': dennoucement?.id === selectedDennoucement?.id }"
                (click)="selectDennoucement(dennoucement)">
                <div class="position-relative">
                  <img [ngStyle]="{'border': '3px solid' + dennoucement?.color }"
                    src="./../../../../assets/imgs/user-picture.png" />
                </div>
                <div class="ml-5 my-auto username">{{ dennoucement?.name }}</div>

                <div class="d-flex align-items-center justify-content-end">
                  @if(dennoucement?.unreadMessages){
                  <div class="interactions__user-messages-count--circle">
                    <div class="interactions__user-messages-count--value">
                      {{ dennoucement?.unreadMessages }}
                    </div>
                  </div>
                  }
                </div>
              </div>
              }
            </div>
          </div>
          <app-chat></app-chat>
          <div class="interactions__user-detais-div">
            <app-interaction-details></app-interaction-details>
            <app-interaction-photos></app-interaction-photos>
            <app-dennoucement-map></app-dennoucement-map>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="interactions_collapse_details">
    <div class="closebtn">
      <label for="check-three-points"><img src="assets/imgs/close_gray.svg"></label>
    </div>
    <app-interaction-details></app-interaction-details>
    <app-interaction-photos></app-interaction-photos>
    <app-dennoucement-map></app-dennoucement-map>
  </div>
</section>
