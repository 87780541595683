import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InteractionsService {
  constructor() { }

  getDennoucementTypes() {
    return of([
      {
        id: 1,
        name: 'Roubos',
        unreadMessages: 3,
        img: '',
        status: 'online',
      },
      {
        id: 2,
        name: 'Lorem Ipsum',
        unreadMessages: 5,
        img: '',
        status: 'online',
      },
      {
        id: 3,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'online',
      },
      {
        id: 4,
        name: 'Lorem Ipsum',
        unreadMessages: 1,
        img: '',
        status: 'busy',
      },
      {
        id: 5,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'away',
      },
      {
        id: 6,
        name: 'Lorem Ipsum',
        unreadMessages: 2,
        img: '',
        status: 'away',
      },
      {
        id: 7,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'busy',
      },
      {
        id: 8,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'busy',
      },
      {
        id: 9,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'online',
      },
      {
        id: 10,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'online',
      },
      {
        id: 11,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'away',
      },
      {
        id: 12,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        status: 'away',
      },
    ]);
  }

  getDennoucements() {
    return of([
      {
        id: 1,
        name: 'Roubo de carro',
        unreadMessages: 3,
        img: '',
        color: '#ffcc00'
      },
      {
        id: 2,
        name: 'Roubo a mão armada',
        unreadMessages: 5,
        img: '',
        color: '#cc3300'
      },
      {
        id: 3,
        name: 'Roubo de banco',
        unreadMessages: 0,
        img: '',
        color: '#22bb33'
      },
      {
        id: 4,
        name: 'Lorem Ipsum',
        unreadMessages: 1,
        img: '',
        color: '#5bc0de',
      },
      {
        id: 5,
        name: 'Roubo de carro',
        unreadMessages: 3,
        img: '',
        color: '#ffcc00'
      },
      {
        id: 6,
        name: 'Roubo a mão armada',
        unreadMessages: 5,
        img: '',
        color: '#cc3300'
      },
      {
        id: 7,
        name: 'Roubo de banco',
        unreadMessages: 0,
        img: '',
        color: '#22bb33'
      },
      {
        id: 8,
        name: 'Lorem Ipsum',
        unreadMessages: 1,
        img: '',
        color: '#5bc0de',
      },
      {
        id: 9,
        name: 'Lorem Ipsum',
        unreadMessages: 1,
        img: '',
        color: '#cc3300',
      },
      {
        id: 10,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        color: '#cc3300',
      },
      {
        id: 11,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        color: '#5bc0de',
      },
      {
        id: 12,
        name: 'Lorem Ipsum',
        unreadMessages: 0,
        img: '',
        color: '#5bc0de',
      },
    ]);
  }
}
