import { Routes } from '@angular/router';

import { SIDEBAR_OPTIONS_URLS } from './shared/enums/sidebar-option-urls.enum';
import { PORTAL_TYPE_URLS } from './shared/enums/portal-types-urls.enum';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/services/auth/auth-guard.service';
import { PortalComponent } from './shared/components/portal/portal.component';
import { AnnouncementComponent } from './shared/components/announcement/announcement.component';
import { AddAnnouncementComponent } from './shared/components/announcement/add-announcement/add-announcement.component';
import { InteractionsComponent } from './shared/components/interactions/interactions.component';
import { EXTRAS_PAGES_URLS } from './shared/enums/extras-pages-urls.enum';
import { NewUserPageComponent } from './shared/components/new-user-page/new-user-page.component';
import { ViewReportPageComponent } from './shared/components/view-report-page/view-report-page.component';
import { ViewUserPageComponent } from './shared/components/view-user-page/view-user-page.component';
import { AuthGuardLogin } from './shared/services/auth_login/auth-guard_login.service';
import { ReportedPage190rjComponent } from './190rj/pages/reported/reported-page190rj.component';
import { EmergencyPage190rjComponent } from './190rj/pages/emergency/emergency-page190rj.component';
import { UsersPage190rjComponent } from './190rj/pages/users/users-page190rj.component';
import { PortalRedeMulherComponent } from './rede-mulher/portal-rede-mulher.component';
import { RedeMulherGeneralPanelComponent } from './rede-mulher/general-panel/general-panel.component';
import { EmergencyPageRedeMulherComponent } from './rede-mulher/emergency/emergency-page-rede-mulher.component';
import { UsersPageRedeMulherComponent } from './rede-mulher/users/users-page-rede-mulher.component';
import { PortalRedeEscolaComponent } from './rede-escola/portal-rede-escola.component';
import { RedeEscolaGeneralPanelComponent } from './rede-escola/general-panel/general-panel.component';
import { Portal190rjComponent } from './190rj/core/portal.component';
import { GeneralPanelComponent } from './190rj/pages/general-panel/general-panel.component';

export const routes: Routes = [
  {
    path: PORTAL_TYPE_URLS.LOGIN,
    component: LoginComponent,
    canActivate: [AuthGuardLogin],
  },
  {
    path: PORTAL_TYPE_URLS.PORTAL,
    component: PortalComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PORTAL_TYPE_URLS.RJ_190,
      },
      {
        path: PORTAL_TYPE_URLS.RJ_190,
        component: Portal190rjComponent,
        canActivateChild: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
            component: GeneralPanelComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.REPORTED,
            component: ReportedPage190rjComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.REPORTED + '/' + EXTRAS_PAGES_URLS.VIEW_REPORTED,
            component: ViewReportPageComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.EMERGENCY,
            component: EmergencyPage190rjComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS,
            component: AnnouncementComponent,
          },
          {
            path:
              SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS +
              '/' +
              EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS,
            component: AddAnnouncementComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.INTERACTIONS,
            component: InteractionsComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.USERS,
            component: UsersPage190rjComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.USERS + '/' + EXTRAS_PAGES_URLS.NEW_USER,
            component: NewUserPageComponent,
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
          },
        ],
      },
      {
        path: PORTAL_TYPE_URLS.REDE_MULHER,
        component: PortalRedeMulherComponent,
        canActivateChild: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
            component: RedeMulherGeneralPanelComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL + '/' + EXTRAS_PAGES_URLS.VIEW_USER,
            component: ViewUserPageComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL + '/' + EXTRAS_PAGES_URLS.VIEW_REPORTED,
            component: ViewReportPageComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.EMERGENCY,
            component: EmergencyPageRedeMulherComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS,
            component: AnnouncementComponent,
          },
          {
            path:
              SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS +
              '/' +
              EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS,
            component: AddAnnouncementComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.USERS,
            component: UsersPageRedeMulherComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.USERS + '/' + EXTRAS_PAGES_URLS.NEW_USER,
            component: NewUserPageComponent,
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
          },
        ],
      },
      {
        path: PORTAL_TYPE_URLS.REDE_ESCOLA,
        component: PortalRedeEscolaComponent,
        canActivateChild: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
            component: RedeEscolaGeneralPanelComponent,
          },
          {
            path: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL + '/' + EXTRAS_PAGES_URLS.VIEW_REPORTED,
            component: ViewReportPageComponent,
          },

          {
            path: '**',
            pathMatch: 'full',
            redirectTo: SIDEBAR_OPTIONS_URLS.GENERAL_PANEL,
          },
        ],
      },
    ],
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: PORTAL_TYPE_URLS.PORTAL,
  },
];
