import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
import { getPortalTypeFromLink } from '../../../shared/utils';
import { Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../../shared/enums/portal-types-urls.enum';
@Injectable({
  providedIn: 'root'
})
export class AnnoucementsService {
  private readonly baseUrlRJ190 = environment.baseUrl190RJ;
  private readonly baseUrlRedeMulher = environment.baseUrlRedeMulher;
  constructor(
    private restApiService: RestApiService,
    private _router: Router
  ) { }

  getAnnoucements(limit?: number, page?: number) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    const request: any = {
      baseUrl: getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher,
      endpoint: `anuncio-banners?limit=${limit}&page=${page}`,
    };
    return this.restApiService.get(request);
  }

  activeBanner(bannersIDs: Array<any>) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher;
    endpoint += 'anuncio-banners/ativar';
    let body = {
      "bannerIds": bannersIDs
    }
    return this.restApiService.put(endpoint, body);
  };

  inactiveBanner(bannersIDs: Array<any>) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher;
    endpoint += 'anuncio-banners/inativar';
    let body = {
      "bannerIds": bannersIDs
    };
    return this.restApiService.put(endpoint, body);
  };

  deleteBanner(bannersIDs: Array<any>) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher;
    endpoint += 'anuncio-banners/deletar';
    let body = {
      "bannerIds": bannersIDs
    }
    return this.restApiService.delete(endpoint, {
      body: body
    });
  };

  getBannerById(id: number) {
    const request: any = {
      baseUrl: getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher,
      endpoint: `anuncio-banners/${id}`,
    };
    return this.restApiService.get(request);
  };

  changeDataBanner(body: any, idBanner: number) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher;
    endpoint += `anuncio-banners/${idBanner}`;
    return this.restApiService.put(endpoint, body);
  };

  addNewBanner(body: any) {
    let endpoint = getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher + ``;
    endpoint += 'anuncio-banners';
    return this.restApiService.post(endpoint, body);
  };

  getAvaliableOrders() {
    const request: any = {
      baseUrl: getPortalTypeFromLink(this._router.url) === PORTAL_TYPE_URLS.RJ_190 ? this.baseUrlRJ190 : this.baseUrlRedeMulher,
      endpoint: `anuncio-banners/nr-ordem`,
    };
    return this.restApiService.get(request);
  };

}
