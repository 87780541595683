import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InteractionsService } from './services/interactions.service';
import { ChatComponent } from './chat/chat.component';
import { InteractionDetailsComponent } from './interaction-details/interaction-details.component';
import { PhotosComponent } from './photos/photos.component';
import { DennoucementMapComponent } from './dennoucement-map/dennoucement-map.component';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';

@Component({
  selector: 'app-interactions',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SidebarOptionTitleBandComponent,
    ChatComponent,
    InteractionDetailsComponent,
    PhotosComponent,
    DennoucementMapComponent,
    FormsModule,
    DynamicButtonComponent
  ],
  templateUrl: './interactions.component.html',
  styleUrl: './interactions.component.scss',
})
export class InteractionsComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({});
  dennoucementsTypes: Array<any> = [];
  dennoucements: Array<any> = [];
  selectedDennoucement: any;
  selectedDennoucementType: number;
  configButtonSearch: ButtonConfig = { label: 'Pesquisar', customWidth: '100%', handler: () => this.searchDennoucements() };

  constructor(
    private _formBuilder: FormBuilder,
    private _interactionsService: InteractionsService
  ) { }

  ngOnInit(): void {
    this.initializeFormGroup();
    this.getTypesDennoucements();
    this.getDennoucements();
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      userName: ['', []],
    });
  }

  getTypesDennoucements() {
    this._interactionsService.getDennoucementTypes().subscribe((resAPI) => {
      this.selectedDennoucementType = resAPI[0].id;
      this.dennoucementsTypes = resAPI;
    });
  };

  getDennoucements() {
    this._interactionsService.getDennoucements().subscribe((resAPI) => this.dennoucements = resAPI);
  }

  selectDennoucement(dennoucement: any) {
    if (dennoucement?.id === this.selectedDennoucement?.id) this.selectedDennoucement = {};
    else this.selectedDennoucement = dennoucement;
  }

  searchDennoucements() {

  }
}
