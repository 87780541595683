import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { authSecretKey } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthService,
    private router: Router,
    private localStorage: LocalStorageService) { }

  canActivate(): boolean {
    return this.checkAuth();
  }

  canActivateChild(): boolean {
    return this.checkAuth();
  }

  canLoad(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.authService.isAuthenticatedUser()) return true;
    this.localStorage.removeItem(authSecretKey);
    this.router.navigate(['/login']);
    return false;
  }
}
