import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeneralPanel190RjService } from '../../../190rj/services/general-panel190rj/general-panel190RJ.service';
import { TableGeneralPanel190RJService } from '../../../190rj/services/dynamic-table/dynamic-table__general-panel190rj.service';
import { BarChartComponent } from '../../../shared/components/bar-chart/bar-chart.component';
import { PORTAL_FILTER_CARD_TITLES } from '../../../shared/components/filter-card/enums/filter-card-titles.enum';
import { FilterCardComponent } from '../../../shared/components/filter-card/filter-card.component';
import { FilterCardsService } from '../../../shared/components/filter-card/services/filter-cards.service';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { LineChartComponent } from '../../../shared/components/line-chart/line-chart.component';
import { SingleDoughnutChartComponent } from '../../../shared/components/single-doughnut-card/single-doughnut-card.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { TopFiveComponent } from '../../../shared/components/top-five/top-five.component';
import { PORTAL_TYPE_URLS } from '../../../shared/enums/portal-types-urls.enum';
import { PORTAL_TYPES } from '../../../shared/enums/portal-types.enum';
import { PortalFilterCard } from '../../../shared/models/portal-filter-card';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { downloadFile, getDateDDMMYYYY } from '../../../shared/utils';
import { DailyUserAnalysisComponent } from '../../../shared/components/daily-user-analysis/daily-user-analysis.component';

@Component({
  selector: 'app-general-panel',
  standalone: true,
  imports: [
    CommonModule,
    SidebarOptionTitleBandComponent,
    FilterCardComponent,
    LineChartComponent,
    SingleDoughnutChartComponent,
    BarChartComponent,
    DailyUserAnalysisComponent,
    TopFiveComponent,
    HeaderBoxTableComponent,
  ],
  templateUrl: './general-panel.component.html',
  styleUrl: './general-panel.component.scss',
})
export class GeneralPanelComponent implements OnInit, OnDestroy {
  portalCards: Array<PortalFilterCard> = [];
  portalCardsSubscription: Subscription | null = null;
  myEventSubscribeCurrentPortal: Subscription | null = null;
  selectedPortalFilterCard: PortalFilterCard | null = null;
  portalCardsTitle = PORTAL_FILTER_CARD_TITLES;
  portalTypes = PORTAL_TYPE_URLS;
  tableConfig: any = {};
  topFive: Array<any> = [];
  topFiveData: any = {};
  currentYear: string;

  constructor(
    private _portalFilterCardsService: FilterCardsService,
    public router: Router,
    private _tableService: TableGeneralPanel190RJService,
    private generalPanelRJ190: GeneralPanel190RjService,
    private loadingService: LoadingService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.emitNewPortalFilterCards();
    this.listenToNewPortalCards();
    this.listenToChangesOnSelectedPortalFilterCard();
    await this.getNumericDatas();
    this.loadingService.dismiss();
  };

  ngOnDestroy() {
    if (this.portalCardsSubscription) this.portalCardsSubscription?.unsubscribe();
    if (this.myEventSubscribeCurrentPortal) this.myEventSubscribeCurrentPortal?.unsubscribe();
  }

  getNumericDatas(): Promise<boolean> {
    return new Promise((resolve) => {
      this.generalPanelRJ190.getTotalStatics().subscribe({
        next: (response: any) => {
          this.portalCards.forEach(card => {
            if (card.title == 'Usuários') card.value = response.totalUsuarios;
            if (card.title == 'Emergências') card.value = response.totalEmergencias;
            if (card.title == 'Denúncias') card.value = response.totalDenuncias;
            if (card.title == 'Desaparecidos') card.value = response.totalDesaparecidos;
          });
        },
        error: () => {
          this.openToast('Erro ao pegar dados númericos!');
        }
      }).add(() => resolve(true));
    })
  };

  topEmergencyData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.generalPanelRJ190.emergencyTops().subscribe({
        next: (response) => {
          response.forEach((oneTop: any, index: any) => {
            oneTop.title = oneTop.tipoChamado;
            oneTop.value = oneTop.total;
            oneTop.color = index % 2 == 0 ? '#895BF1' : '#FEBF0F';
            delete oneTop.tipoChamado;
            delete oneTop.total;
            this.topFive.push(oneTop);
          });
          var emegencyCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES;
          })[0];
          this.topFiveData.total = emegencyCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  topDenouncementData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.generalPanelRJ190.denouncementTops().subscribe({
        next: (response) => {
          response.forEach((oneTop: any, index: any) => {
            oneTop.title = oneTop.tipoDenuncia;
            oneTop.value = oneTop.total;
            oneTop.color = index % 2 == 0 ? '#0090FF' : '#FEBF0F';
            delete oneTop.tipoDenuncia;
            delete oneTop.total;
            this.topFive.push(oneTop);
          });
          var denouncementCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS;
          })[0];
          this.topFiveData.total = denouncementCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  async clickCard() {
    this.loadingService.present();
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_DENOUNCEMENTS) {
      await this.topDenouncementData();
      await this.getConfigTable();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_EMERGENCIES) {
      await this.topEmergencyData();
      await this.getConfigTable();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_MISSING) {
      await this.getConfigTable();
    };
    this.loadingService.dismiss();
  }

  getConfigTable(): Promise<boolean> {
    return new Promise(async resolve => {
      if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_USERS) return resolve(false);
      this.tableConfig = await this.getData(1);
      this.tableConfig.currentPage = 1;
      resolve(true);
    });
  };

  async swapPage(page: number) {
    this.loadingService.present();
    this.tableConfig = await this.getData(page, this.currentYear);
    this.tableConfig.currentPage = page;
    this.loadingService.dismiss();
  }

  emitNewPortalFilterCards() {
    this._portalFilterCardsService.emitNewPortalFilterCards(
      PORTAL_TYPES.RJ_190
    );
  }

  listenToNewPortalCards() {
    this.portalCardsSubscription =
      this._portalFilterCardsService.portalFilterCard$.subscribe(
        (portalCards: Array<PortalFilterCard>) => {
          this.portalCards = portalCards;
        }
      );
  }

  listenToChangesOnSelectedPortalFilterCard() {
    this.myEventSubscribeCurrentPortal = this._portalFilterCardsService.currentPortalFilterCard$.subscribe(
      (selectedPortalFilterCard: PortalFilterCard | null) => {
        if (selectedPortalFilterCard) {
          this.selectedPortalFilterCard = selectedPortalFilterCard;
          this.topFiveData.title = selectedPortalFilterCard.title;
          this.portalCards.forEach((portalCard: PortalFilterCard) => {
            portalCard.active = false;
            if (portalCard.id === selectedPortalFilterCard.id) {
              portalCard.active = true;
            }
          });
        };
      }
    );
  }

  public async searchAndFilterItens(event: any) {
    if (event.filterFirstValue) {
      this.currentYear = event.filterFirstValue;
      this.loadingService.present();
      this.tableConfig = await this.getData(1, this.currentYear);
      this.tableConfig.currentPage = 1;
      this.loadingService.dismiss();
    }
  }

  public clickExcelButton() {
    this.loadingService.present();
    let request: Observable<any> =
      this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_EMERGENCIES ?
        this.generalPanelRJ190.generalPanelEmergencyTableExcelExport(this.currentYear) :
        this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_DENOUNCEMENTS ?
          this.generalPanelRJ190.generalPanelDenoucementTableExcelExport(this.currentYear) :
          this.generalPanelRJ190.generalPanelMissingTableExcelExport(this.currentYear);
    request.subscribe({
      next: (response) => {
        downloadFile(response, `190RJ_${this.selectedPortalFilterCard?.title}_${this.currentYear ? this.currentYear : getDateDDMMYYYY()}`);
        this.loadingService.dismiss();
      },
      error: () => {
        this.openToast('Erro ao baixar arquivo Excel!');
        this.loadingService.dismiss();
      }
    })

  }

  public async clearSearchTable() {
    this.loadingService.present();
    this.currentYear = new Date().getFullYear().toString();
    this.tableConfig = await this.getData(1);
    this.tableConfig.currentPage = 1;
    this.loadingService.dismiss();
  }

  private async getData(page?: number, year?: string) {
    return await this._tableService.getSettingsTable(this.selectedPortalFilterCard, page, year);
  }

  shouldShowTopFive() {
    return (
      (this.selectedPortalFilterCard?.selectedPortal.type ===
        PORTAL_TYPES.RJ_190 &&
        this.selectedPortalFilterCard?.title ===
        PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) ||
      (this.selectedPortalFilterCard?.selectedPortal.type ===
        PORTAL_TYPES.RJ_190 &&
        this.selectedPortalFilterCard?.title ===
        PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS)
    );
  };

  openToast(text: string): void {
    this.toastr.error(text, 'Erro!');
  }
}
