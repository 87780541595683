import { Component } from '@angular/core';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { validateCPF, getPortalTypeFromLink } from '../../utils';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { UsersService } from '../../../190rj/services/users-page/users.service';
import { LoadingService } from '../../services/loading/loading.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-user-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent, DynamicButtonComponent, NgxMaskDirective],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './new-user-page.component.html',
  styleUrl: './new-user-page.component.scss'
})
export class NewUserPageComponent {
  organs: any[] = [];
  profiles: any[] = [];
  show: boolean = false;
  showPass: boolean = false;

  formGroup: FormGroup = new FormGroup({});
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/imgs/back_arrow_ic.svg", handler: () => this.backPage() };

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private usersService: UsersService,
    private loadingService: LoadingService
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.initializeFormGroup();
    this.profiles = await this.getProfiles();
    this.organs = await this.getOrgans();
    this.show = true;
    this.loadingService.dismiss();
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      cpf: [, Validators.compose([Validators.required, this.cpfValidator])],
      phone: [, [Validators.required]],
      cellphone: [, [Validators.required]],
      email: ['', Validators.compose([Validators.email])],
      password: ['', [Validators.required, this.passwordValidator()]],
      organ: [0, [Validators.required, Validators.min(1)]],
      profile: [0, [Validators.required, Validators.min(1)]],
    });
  };

  get password() {
    return this.formGroup.get('password');
  }

  get cpf(): any {
    return this.formGroup.get('cpf');
  }

  get email() {
    return this.formGroup.get('email');
  }

  cpfValidator(control: AbstractControl) {
    if (control.value && !validateCPF(control.value)) {
      return { cpfInvalido: true };
    }
    return null;
  }

  verifyValidCpf(): boolean {
    return validateCPF(this.cpf.value);
  }


  verifyPassContainQuotation(): boolean {
    if (this.password?.value && /['"]/.test(this.password?.value)) return true;
    else return false;
  }

  verifyPassNoContainNumberAndLetters(): boolean {
    if (this.password?.value && !/[a-zA-Z]/.test(this.password?.value) || !/[0-9]/.test(this.password?.value)) return true;
    else return false;
  }

  verifyPassContainSequence(): boolean {
    if (this.password?.value && /123|234|345|456|567|678|789/.test(this.password?.value)) return true;
    else return false;
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const senha = control.value;
      const errors = {};
      if (senha && senha.length < 8) return { senhaCurta: 'A senha deve ter pelo menos 8 caracteres' };
      if (this.verifyPassContainQuotation()) return { senhaComAspas: 'A senha não pode conter aspas' };
      if (this.verifyPassNoContainNumberAndLetters()) return { senhaSemLetraENumero: 'A senha deve conter letras e números' };
      if (this.verifyPassContainSequence()) return { senhaComNumerosSequenciais: 'A senha não pode conter números sequenciais' };
      return null;
    };
  }

  getProfiles(): Promise<any[]> {
    return new Promise(resolve => {
      this.usersService.getProfiles().subscribe({
        next: (response) => {
          resolve(response.data);
        },
        error: () => {
          resolve([]);
        },
      })
    })
  }

  getOrgans(): Promise<any[]> {
    return new Promise(resolve => {
      this.usersService.getOrgans().subscribe({
        next: (response) => {
          resolve(response.data);
        },
        error: () => {
          resolve([]);
        },
      })
    })
  }

  backPage() {
    window.history.back();
  }

  saveUser() {
    this.loadingService.present();
    let requestBody = {
      "nome": this.formGroup.value.name,
      "cpf": this.formGroup.value.cpf.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      "telefone": this.formGroup.value.phone.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      "celular": this.formGroup.value.cellphone.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      "email": this.formGroup.value.email,
      "senha": this.formGroup.value.password,
      "rg": null,
      "funcionalId": null,
      "profileId": Number(this.formGroup.value.profile),
      "orgaoId": Number(this.formGroup.value.organ),
      "unidadeId": null,
      "grauHierarquicoId": null,
      "estaAtivo": true
    };
    this.usersService.createUser(requestBody).subscribe({
      next: () => {
        this.loadingService.dismiss();
        this.router.navigateByUrl(`${getPortalTypeFromLink(this.router.url)}/${SIDEBAR_OPTIONS_URLS.USERS}`).then(() => this.openToast("Cadastro salvo com sucesso.", 'success'));
      },
      error: (err: any) => {
        if (err?.error?.message) this.openToast(err?.error?.message, 'error');
        else this.openToast('Erro ao cadastrar novo usuário!', 'error');
        this.loadingService.dismiss();
      },
    });
  }

  openToast(text: string, type: string): void {
    if (type == 'error') {
      this.toastr.error(text, 'Erro!');
    } else {
      this.toastr.success(text, 'Sucesso!');
    }
  }
}
