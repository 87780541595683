import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PortalTypeService } from '../shared/services/portal-type.service';
import { PORTAL_TYPES } from '../shared/enums/portal-types.enum';
import { PORTAL_TYPES_NAMES } from '../shared/enums/portal-types-names.enum';



@Component({
  selector: 'app-portal-rede-mulher',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './portal-rede-mulher.component.html',
  styleUrl: './portal-rede-mulher.component.scss',
})
export class PortalRedeMulherComponent implements OnInit {
  constructor(
    private readonly _portalTypeService: PortalTypeService
  ) { }

  ngOnInit(): void {
    this.initializePortalType();
  }

  initializePortalType() {
    this._portalTypeService.currentPortalType$.next({
      type: PORTAL_TYPES.REDE_MULHER,
      name: PORTAL_TYPES_NAMES.REDE_MULHER,
    });
  }
}
