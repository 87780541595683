<section class="portal-body__daily-user-charts-card d-flex flex-column">
  <div class="daily-user-analysis--header d-flex flex-wrap justify-content-between">
    <div class="pl-0 pr-5">
      <div class="daily-user-analysis__title">Análise Diária Usuários</div>
    </div>
    <div class="pr-0 daily-user-analysis--form">
      <form [formGroup]="formGroup" class="position-relative w-fit-content ml-auto form--container">
        <img class="arrow-down" src="./../../../../assets/imgs/arrow-down.svg" />
        <select class="form-select w-fit-content" formControlName="period" [value]="periods[0].name">
          @for(period of periods; track period.id){
          {{
          period.name
          }}
          <option [value]="period.name">{{ period.name }}</option>
          }
        </select>
      </form>
    </div>
  </div>

  <div class="daily-user-analysis--grid flex-grow-1 position-relative">
    <div class="vertical-separation">
      <div class="doughnut-chart--container position-relative">
        <canvas class="user-analysis-chart" id="user-analysis-1"></canvas>
        <div class="inside-chart">
          <div>75%</div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="started-following--value text-center">100</div>
        <div class="text-center started-following--description">
          Começaram a seguir
        </div>
        <div class="d-flex align-items-center started-following--percentage">
          <div class="started-following--percentage-green-box d-flex align-items-center justify-content-center">
            <img src="./../../../../assets/imgs/arrow-up.svg" class="started-following--percentage-image" />
          </div>
          <div>+{{ 16.7 / 100 | percent : "1.2-2" : "pt-BR" }}</div>
        </div>
      </div>
    </div>
    <div>
      <div class="doughnut-chart--container position-relative">
        <canvas class="user-analysis-chart" id="user-analysis-2"> </canvas>
        <div class="inside-chart">
          <div>76%</div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="started-following--value text-center">20</div>
        <div class="text-center started-following--description">
          Deixaram de seguir
        </div>
        <div class="d-flex align-items-center stopped-following--percentage">
          <div class="stopped-following--percentage-red-box d-flex align-items-center justify-content-center">
            <img src="./../../../../assets/imgs/arrow-up.svg" class="stopped-following--percentage-image" />
          </div>
          <div>-{{ 5.50 / 100 | percent : "1.2-2" : "pt-BR" }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
