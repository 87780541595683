import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../../shared/services/rest-api/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class EmergencyService {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  emergencyPageTable(limit?: number, page?: number, type?: string, status?: string, initDate?: string, finalDate?: string) {
    if (!limit) limit = 10;
    if (!page) page = 1;
    if (type == 'todos') type = '';
    if (status == 'todos') status = '';
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia?${initDate ? `dataInicio=${initDate}` : ''}${finalDate ? `&dataFinal=${finalDate}` : ''}${type ? `&tipoChamado=${type}` : ''}${status ? `&status=${status}` : ''}${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`,
    };
    return this.restApiService.get(request);
  };

  emergencyGetTypes() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/listar-tipos-chamados`,
    };
    return this.restApiService.get(request);
  };

  emergencyGetStatus() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/listar-status`,
    };
    return this.restApiService.get(request);
  };

  emergencyExcel() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: `tabela-emergencia/excel`,
      responseType: 'blob'
    };
    return this.restApiService.get(request);
  };
}
