import { Component } from '@angular/core';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { getPortalTypeFromLink } from '../../utils';
import { PortalFilterCard } from '../../models/portal-filter-card';
import { PORTAL_FILTER_CARD_TITLES } from '../filter-card/enums/filter-card-titles.enum';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../services/loading/loading.service';
import { ReportsService } from '../../../190rj/services/reports-page/reports.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ExpandImageComponent } from '../expand-image/expand-image.component';

@Component({
  selector: 'app-view-report-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './view-report-page.component.html',
  styleUrl: './view-report-page.component.scss'
})
export class ViewReportPageComponent {
  reported = {
    violations: [
      { id: 1, name: 'Violação 1' },
      { id: 2, name: 'Violação 2' },
      { id: 3, name: 'Violação 3' },
      { id: 4, name: 'Violação 4' },
      { id: 5, name: 'Violação 5' },
    ],
    whatToDo: [
      { id: 1, name: 'Manter a publicação' },
      { id: 2, name: 'Deletar a publicação' },
      { id: 3, name: 'Deletar a publicação e bloquear o usuário autor' },
    ],
    images: [
      {},
      {},
      {},
      {}
    ]
  };
  portal: string;
  portals = PORTAL_TYPE_URLS;
  reportData: any;
  title: string = "";
  imgProfile: string;
  showPage: boolean = false;
  card: PortalFilterCard;
  formGroup: FormGroup = new FormGroup({});
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/imgs/back_arrow_ic.svg", handler: () => this.navToRetrocessPage() };
  pageOnlyShowData: boolean = true;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private reportsService: ReportsService,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.initializeFormGroup();
    this.card = window.history.state.card;
    this.buildPage();
    this.reportData = await this.getDataReport(window.history.state.card, window.history.state.reportData.id);
    if (!this.reportData) {
      this.openToast('Erro ao buscar a postagem', 'error');
      this.pageOnlyShowData = true;
      this.setForms();
    }
    this.portal = getPortalTypeFromLink(this.router.url);
    this.imgProfile = this.reportData?.imgAutor ? `url(${this.reportData.imgAutor})` : 'url("../../../../assets/imgs/user_img.svg")';
    this.showPage = true;
    this.loadingService.dismiss();
  }

  getDataReport(card: any, id: any): Promise<any> {
    return new Promise(resolve => {
      let require: Observable<any> = card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS ? this.reportsService.getReportById(id) :
        card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS ? this.reportsService.getOpenStatusById(id) :
          card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS ? this.reportsService.getClosedStatusById(id) :
            this.reportsService.getBlockedById(id)
      require.subscribe({
        next(value) {
          resolve(value[0]);
        },
        error() {
          resolve(null);
        },
      })
    })
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      // violation: [0, [Validators.required, Validators.min(1)]] -> Aguardar ajustar para receber no back,
      violation: [0],
      whatToDo: [0, [Validators.required, Validators.min(1)]],
    });
  }

  saveUser() {
    if (this.formGroup.valid) {
      let body: any = {
        statusReports: false,
        statusUser: false
      };
      if (this.formGroup?.value?.whatToDo == 1) {
        body.statusReports = true;
        body.statusUser = true;
      } else if (this.formGroup?.value?.whatToDo == 2) {
        body.statusReports = false;
        body.statusUser = true;
      };
      let subscribe: Observable<any> | null = this.verifyPage(this.reportData.idDenuncia, body);
      if (subscribe != null) {
        subscribe.subscribe({
          next: () => {
            let portal = getPortalTypeFromLink(this.router.url);
            let page = portal == PORTAL_TYPE_URLS.REDE_MULHER ? SIDEBAR_OPTIONS_URLS.GENERAL_PANEL : SIDEBAR_OPTIONS_URLS.REPORTED;
            this.router.navigateByUrl(`${portal}/${page}`).then(() => this.openToast('Análise feita com sucesso!', 'success'));
          },
          error: (err) => {
            console.log(err);
          },
        })
      }
    }
  };

  verifyPage(id: string, body: any): Observable<any> | null {
    if (this.card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) return this.reportsService.toDoReport(id, body);
    else if (this.card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) return this.reportsService.toDoOpenStat(id, body);
    else return null;
  }

  openToast(message: string, type: string): void {
    if (type == 'success') {
      this.toastr.success(message, 'Sucesso!');
    } else {
      this.toastr.error(message, 'Erro!');
    }
  }

  buildPage() {
    if (this.card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
      this.pageOnlyShowData = false;
      this.title = "Análise Usuário";
    }
    else if (this.card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
      this.pageOnlyShowData = false;
      this.title = "Análise Usuário";
    }
    else if (this.card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
      this.pageOnlyShowData = true;
      this.setForms();
      this.title = "Usuário Bloqueado";
    }
    else if (this.card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
      this.pageOnlyShowData = true;
      this.setForms();
      this.title = PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS;
    }
    else if (this.card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
      this.pageOnlyShowData = false;
      this.title = "Análise Usuário";
    }

    else if (this.card.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
      this.pageOnlyShowData = false;
      this.title = "Denúncia";
    }
  }

  setForms() {
    this.formGroup.setValue({
      violation: 2,
      whatToDo: 2
    });
    this.formGroup.get('violation')?.disable();
    this.formGroup.get('whatToDo')?.disable();
  }

  navToRetrocessPage() {
    let page = this.portal == PORTAL_TYPE_URLS.RJ_190 ? SIDEBAR_OPTIONS_URLS.REPORTED : SIDEBAR_OPTIONS_URLS.GENERAL_PANEL;
    this.router.navigateByUrl(`${this.portal}/${page}`);
  }

  expandPage(imageBase64: string): void {
    if (imageBase64) {
      const dialogRef = this.dialog.open(ExpandImageComponent, {
        data: imageBase64,
        hasBackdrop: true
      });
      dialogRef.afterOpened().subscribe({
        next: () => {
          const body = document.getElementsByTagName("body")[0].style.overflowY = "hidden";
        },
      });
      dialogRef.afterClosed().subscribe({
        next: () => {
          const body = document.getElementsByTagName("body")[0].style.overflowY = "scroll";
        },
      })
    }
  }

}
