<section class="header--container relative" [ngClass]="currentPortalType">
  <input type="checkbox" id="check-menu-hamburguer">
  <label for="check-menu-hamburguer" (click)="showCollpaseMenu()" class="checkbtn">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </label>
  <section class="d-flex align-items-center">
    <img class="header--brasao-pmerj" src="./../../assets/imgs/brasao-pmerj-small.svg" />
    <span class="header--pmerj">Polícia Militar do Estado do Rio de Janeiro</span>
  </section>
  <section class="d-flex align-items-center justify-content-between">
    <section role="button" class="d-flex align-items-center" (click)="updatePortalType()">
      <div class="header--portal-type--img-container position-relative" [ngClass]="currentPortalType">
        <img class="header--portal-type--img position-absolute"
          src="./../../../assets/imgs/{{ currentPortalType }}-small.svg" />
      </div>
      <span class="header--portal-type--title">{{ currentPortalName }}</span>
    </section>
    <section class="header--username-container">
      <span class="header--username">{{nameUser}}</span>
    </section>
    <section (click)="logout()" role="button" class="">
      <img src="./../../../assets/imgs/power.svg" class="header--logout-img" />
    </section>
  </section>

  <div class="side-bar-collapse">
    <app-sidebar-collpse></app-sidebar-collpse>
  </div>
</section>
