export enum PORTAL_FILTER_CARD_TITLES {
  RJ_190_USERS = 'Usuários',
  RJ_190_EMERGENCIES = 'Emergências',
  RJ_190_DENOUNCEMENTS = 'Denúncias',
  RJ_190_MISSING = 'Desaparecidos',
  RJ_190_REPORTS = 'Reports',
  RJ_190_OPEN_STATUS = 'Status Aberto',
  RJ_190_BLOCKED = 'Bloqueados',
  RJ_190_CLOSED_STATUS = 'Status Fechado',
  REDE_MULHER_USERS = 'Usuários',
  REDE_MULHER_EMERGENCIES = 'Emergências',
  REDE_MULHER_SPECIALIZED_CENTER = 'Centros Especializados',
  REDE_MULHER_AGRESSOR = 'Agressores',
  REDE_ESCOLA_USERS = 'Usuários',
  REDE_ESCOLA_SCHOOLS = 'Escolas cadastradas',
  REDE_ESCOLA_DENOUNCEMENTS = 'Denúncias',
  REDE_ESCOLA_EMERGENCIES = 'Emergências',
}
